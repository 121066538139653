<template>
    <div class="lasso-layout">
        <svg class="space-background" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
            <defs>
                <pattern id="stars" width="100" height="100" patternUnits="userSpaceOnUse">
                    <circle v-for="(star, index) in starPositions" :key="index"
                            :cx="star.cx"
                            :cy="star.cy"
                            :r="star.r"
                            fill="white"
                            :style="{ opacity: star.opacity }"/>
                </pattern>
            </defs>
            <rect width="100%" height="100%" fill="#050508"/>
            <rect width="100%" height="100%" fill="url(#stars)"/>
        </svg>
        <div class="content-container">
            <topWelcomeBanner style="position: absolute; top: 60px; width: 100%;"></topWelcomeBanner>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";

export default {
    name: "authLayout",
    components: {
        topWelcomeBanner
    },
    data() {
        return {
            starPositions: Array.from({ length: 50 }, () => ({
                cx: Math.random() * 100,
                cy: Math.random() * 100,
                r: Math.random() * 0.3,
                opacity: 0.3 + Math.random() * 0.4
            }))
        };
    },
    created() {
        this.animateStars();
    },
    methods: {
        animateStars() {
            this.starPositions = this.starPositions.map(star => ({
                ...star,
                opacity: 0.3 + Math.random() * 0.4 // Randomize opacity for twinkling effect
            }));
            requestAnimationFrame(this.animateStars);
        }
    }
}
</script>

<style lang="scss">
.lasso-layout {
    position: relative;
    min-height: 100vh;
    background: #050508;
    overflow: hidden;
}

.space-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    circle {
        animation: twinkle 3s infinite alternate;
    }
}



.content-container {
    position: relative;
    z-index: 2;
    min-height: 100vh;
    width: 100%;
}

@keyframes twinkle {
    0% { opacity: 0.3; }
    100% { opacity: 0.7; }
}
</style>
